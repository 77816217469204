<template>
  <div class="Page">
    <div class="opt-bar flex">
      <el-button @click="handleNewApp" size="small" v-ripple>授权应用</el-button>
      <el-input size="small"
          @keyup.enter.native="onCurrentPageChange(1)"
          class="searcher-input"
          placeholder="关键词"
          clearable
          @clear="onSkeyClear"
          v-model="skey">
          <i slot="prefix" class="el-input__icon el-icon-search"></i>
        </el-input>
      <el-button @click="onCurrentPageChange(1)" type="primary" size="small"><i class="iconfont">&#xe622;</i></el-button>
    </div>
    <split-pane split="vertical" class="pane" :default-percent='70' :resizerThickness="5" @resize="paneResizeVertical">
      <template slot="paneL">
          <el-table :data="page.list" element-loading-text="Loading..."
            size="small"
            border fit highlight-current-row
            header-row-class-name="tableHeader"
            :height="tableHeight"
            :row-class-name="tableRowClass"
            row-key="id"
            default-expand-all
            @row-click="handleRowClick"
            :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
          >
            <el-table-column fixed align="center" label="Id" width="95">
              <template slot-scope="scope">
                {{ scope.row.id }}
              </template>
            </el-table-column>
            <el-table-column label="Logo" width="60" align="left">
              <template slot-scope="scope">
                <el-avatar :size="50" :src="scope.row.head_img"></el-avatar>
              </template>
            </el-table-column>
            <el-table-column label="名称"  align="left">
              <template slot-scope="scope">
                {{ scope.row.nick_name }}
              </template>
            </el-table-column>
            <el-table-column label="状态" width="60" align="center">
              <template slot-scope="scope">
                {{ transStat(scope.row.stat)}}
              </template>
            </el-table-column>
            <el-table-column label="类型" width="60" align="center">
              <template slot-scope="scope">
                {{ transAuthType(scope.row.auth_type)}}
              </template>
            </el-table-column>
            <el-table-column label="授权时间" width="160" align="left" class="hidden-md-and-down">
              <template slot-scope="scope">
                {{util.formatDate(scope.row.authtime)}}
              </template>
            </el-table-column>
            <el-table-column label="操作" width="70" align="center">
              <template slot-scope="scope">
                <icon-btn @click="handleTraceLog(scope.row)" tip="跟踪"
                  icon="iconxtracelianluzhuizong"
                  type="warning"
                  ></icon-btn>
              </template>
            </el-table-column>
          </el-table>
          <div class="pager">
            <div class="pager-info">共 {{page.total}} 条 第 {{page.currentPage}}/{{page.pageCount}} 页</div>
            <el-pagination
              @size-change="onPageSizeChange"
              @current-change="onCurrentPageChange"
              :current-page="page.currentPage"
              :page-size="page.pageSize"
              layout="total, prev, pager, next, jumper"
              :total="page.total">
            </el-pagination>
            <div class="pager-time1" v-if="queryTime > 0">查询{{queryTime}}毫秒</div>
          </div>
        </template>
      <template slot="paneR">
        <div class="pane-right" v-if="curRow">
          <el-card class="box-card">
            <row>
              <el-avatar :size="50" :src="curRow.head_img"></el-avatar>
              <span class="nick-name">{{curRow.nick_name}}</span>
            </row>
            <row label="类型" :text="transAuthType(curRow.auth_type)" />
            <row label="公众号服务类型" :text="transServiceType(curRow.service_type_info)" />
            <row label="授权时间" :text="curRow.authtime" />
            <row label="应用ID" :text="curRow.appid" />
            <row label="公司名称" :text="curRow.principal_name" />
            <row label="二维码">
              <template slot="text">
                <!-- <img  v-if="curRow.qrcode_mp" class="qrcode" :src="curRow.qrcode_mp"/> -->
                <img v-if="curRow.qrcode_url" class="qrcode" :src="curRow.qrcode_url"/>
                <span v-else>暂无</span>
              </template>
            </row>
            <row>
              <el-button @click="queryRidShow=true">错误代码查询</el-button>
            </row>
          </el-card>
        </div>
      </template>
    </split-pane>
    <el-dialog
      title="腾讯错误代码查询"
      :visible.sync="queryRidShow"
      :close-on-click-modal="false"
      width="50%"
      >
      <div>
        <div class="center">   
          <label>错误代码：</label>       
          <el-input            
            placeholder="请输入腾讯的错误代码：rid"
            v-model="rid"
            style="width: 50%"
            size="small"
            @keyup.enter="queryRid"            
            clearable>
          </el-input>
          <el-button @click="queryRid" type="primary" size="mini"><i class="iconfont">&#xe622;</i></el-button>
        </div>
        <div class="rid-result" v-if="ridResult">
          <vue-json-editor class="json-editor" v-model="ridResult" :expandedOnStart="true" :show-btns="false" :mode="'code'" lang="zh">
          </vue-json-editor>          
        </div>
      </div>      
      <span slot="footer" class="dialog-footer">
        <el-button @click="queryRidShow = false">取 消</el-button>
        <el-button type="primary" @click="queryRidShow = false">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import VueJsonEditor from 'vue-json-editor'
import encryptUtil from '@/utils/encrypt.js'
import splitPane from 'vue-splitpane'
import IconBtn from '../../components/btns/IconBtn';
import Row from '../../components/row/Row'
export default {
  components: { splitPane, IconBtn, Row, VueJsonEditor},
  data() {
    return {
      login: {role: '', platid: false, plats: []},
      tableHeight: 560,
      skey: '',
      page: {pg: 1, list:[], total: 0, pageSize: 20},
      queryTime: 0,
      curRow: false,
      queryRidShow: false,
      rid: '',
      ridResult: false,
    };
  },
  created(opt) {
    this.login = this.getItem('login')
    this.resize();
    window.onresize = this.resize();
    this.onCurrentPageChange(1)
  },
  methods: {
    queryRid() {
      var pm = {
        appid: this.curRow.appid,
        rid: this.rid
      }
      this.post('api/me/platRidQuery', pm, res=>{
        if (res.code == 0) {
          console.log(this.ridResult)
          this.ridResult = res
        } else {
          this.ridResult = res;
          this.$notify.error('查询失败：' + res.msg)
        }
      })
    },
    handleNewApp() {
      const key = this.global.encrypt_key
      var token = this.global.token.replace('Bearer ', '')
      console.log(token);
      var etoken = encryptUtil.encrypt(token, key);
      etoken = encodeURIComponent(etoken)
      var url = this.global.api + '/home/new_app?token=' + etoken
      window.open(url);
    },
    handleRowClick(row) {
      this.curRow = row
    },
    paneResizeVertical(e) {
      // console.log(e)
    },
    resize() {
      var clientHeight = document.documentElement.clientHeight
      this.tableHeight = clientHeight - 160
    },
    onSkeyClear() {
      this.onCurrentPageChange(1)
    },
    async onCurrentPageChange(pg){
      var pm = {
        pg : pg,
        pageSize: this.page.pageSize,
        skey: this.skey
      }
      this.post('api/me/plats', pm, res=>{
        if (res.code != 0) {
          this.$message.error('出错了~' + res.msg);
          return
        }
        this.queryTime = res.pageTime
        this.page = res.data
        if (this.page.list.length > 0) {
          this.handleRowClick(this.page.list[0])
        }
      })
    },
    onPageSizeChange(e) {
      this.page.pageSize = e;
      this.onCurrentPageChange(1)
    },
    transStat(stat) {
      var res = ''
      switch(stat) {
        case 0:
          res = '未启用'
          break;
        case 1:
          res = '已启用'
          break;
      }
      return res
    },
    tableRowClass({row, rowIndex}) {
      if (row.upid == 0) {
        return 'success-row'
      }
      if (row.stat === 0) {
        return "error-row"
      } else {
        if (rowIndex % 2 === 0) {
          return '';
        } else {
          return 'second-row'
        }
      }
    },
    transAuthType(auth_type) {
      switch(auth_type) {
        case 1:
          return '公众号'
        case 2:
          return '小程序'
      }
      return ''
    },
    transServiceType(service_type_info) {
      switch(service_type_info) {
        case 0:
          return '订阅号'
        case 1:
          return '订阅号(old version)'
        case 2:
          return '服务号'
      }
      return ''
    },

  }//methods
};
</script>

<style scoped lang="scss">
.Page {
  margin-top: 0px;

  .test {
    color: $test;
  }
}
.pager-time1 {
  float: right;
  margin-right: 10px;
  margin-top: -20px;
}

.box-card {
  .nick-name {
    font-size: 1.3em;
    padding-top: 13px;
    font-weight: 700;
    color: $dark;
  }

  .qrcode{
    width: 160px;
    height: 160px;
  }
}

.rid-result {
  margin-top: 1em;
  border-top: 1px solid #cbcbcb;
  padding-top: 0.5em;
}
.rid-result {
  width: 100%;  
  .json-editor {
    width: 100%;
    min-height: 250px;
    text-align: left;
  }
}
</style>
